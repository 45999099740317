import { useState } from "react";
import Grid from "@mui/material/Grid2";
import AssetHistory from "../../assets/assetHistory/AssetHistory";
import InventoryHistory from "../../inventory/inventoryHistory/InventoryHistory";
import RadioList from "../../../components/Forms/FieldTypes/RadioList";

export default function InventoryHistoryGrid(props) {
  const { userRoles = {} } = props;
  const {
    "View Inventory": viewInventory = null,
    "View Asset History": viewAssetHistory = null,
  } = userRoles;

  const defaultTableValue = viewInventory
    ? "Inventory"
    : viewAssetHistory
    ? "Asset"
    : null;

  // I have set this as a string and am rendering tables based on the string value. As I dont know if
  // we will need to add more tables here or not.
  const [state, setState] = useState({ activeTable: defaultTableValue });
  return (
    (<Grid container>
      <Grid size={12}>
        <RadioList
          label={`${state.activeTable} History`}
          name="table"
          onChange={(event) => setState({ activeTable: event.target.value })}
          options={[
            {
              label: "Inventory",
              value: "Inventory",
              disabled: !viewInventory,
            },
            { label: "Asset", value: "Asset", disabled: !viewAssetHistory },
          ]}
          row
          value={state.activeTable}
        />
      </Grid>
      <Grid size={12}>
        {state.activeTable === "Inventory" ? (
          <InventoryHistory {...props} label="History" exportTable={true} />
        ) : null}
        {state.activeTable === "Asset" ? (
          <AssetHistory {...props} label="History" exportTable={true} />
        ) : null}
      </Grid>
    </Grid>)
  );
}
