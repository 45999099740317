import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { updateUsersMap } from "../../globalStore/slices/organization/organizationSlice";
import { updateUserTimezone } from "../../globalStore/slices/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation as UseTranslation } from "react-i18next";
import AutocompleteSelect from "../../components/Forms/FieldTypes/AutocompleteSelect";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import moment from "moment";
import SimpleCheckbox from "../../components/Forms/FieldTypes/Checkbox";

function UserProfile(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { typography = {} } = palette;

  const classes = {
    profileDetail: {
      color: typography?.secondary,
      fontFamily: "Lato",
      fontSize: "1.1rem",
      fontWeight: "bold",
    },
  };

  const { apiUrl, token } = props;

  const { t } = UseTranslation();


  const user = useSelector((state) => state.user);
  const dispatchGlobal = useDispatch();

  const {
    appUserId,
    email,
    firstName,
    lastName,
    usersConsoleRole,
    userPropertiesMap,
    userTimeZone,
    userTimeZoneUseDefault,
  } = user;

  const timeZones = moment.tz.names();
  const browserTimeZone = moment.tz.guess();
  const usersSetTimeZone = userTimeZone ? userTimeZone : browserTimeZone;
  const timeZoneOptions =
    timeZones && timeZones.length
      ? timeZones.map((tz) => {
          return {
            label: tz,
            value: tz,
          };
        })
      : [];

    const phoneNumber = userPropertiesMap?.propertiesMap?.phone;
    
    const [state, setState] = useState({
    timeZone: {
      error: false,
      id: "userType",
      value: { label: usersSetTimeZone, value: usersSetTimeZone },
    },
    userTimeZoneUseDefault: {
      id: "userTimeZoneUseDefault",
      value: userTimeZoneUseDefault,
    },
  });

  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    message: "",
    type: "success",
  });

  const { timeZone } = state;
  const fetchUrl = `${apiUrl}appUsers/${appUserId}`;

  function handleSaveTimeZone() {
    // Save the timezone to the user object in the database.
    fetch(fetchUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        userTimeZone: timeZone.value.value,
        userTimeZoneUseDefault: state.userTimeZoneUseDefault.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          //update user in redux store
          dispatchGlobal(
            updateUserTimezone({
              timeZone: response.appUser?.userTimeZone,
              userTimeZoneUseDefault: response.appUser?.userTimeZoneUseDefault,
            })
          );
          dispatchGlobal(
            updateUsersMap({
              appUserId: response.appUser?.appUserId,
              body: response.appUser,
            })
          );
          //set notification modal state
          setConfirmationModal((prevState) => ({
            ...prevState,
            open: true,
            message: "Timezone saved successfully",
            type: "success",
          }));
        } else {
          setConfirmationModal((prevState) => ({
            ...prevState,
            open: true,
            message: `Error: ${response.error}`,
            type: "error",
          }));
        }
      });
  }

  return (
    (<Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "1rem",
      }}
    >
      {/* confirmation modal */}
      <MaterialConfirmationModal
        closeModal={() => {
          setConfirmationModal((prevState) => ({
            ...prevState,
            open: false,
          }));
        }}
        content={confirmationModal.message}
        modalOpen={confirmationModal.open}
        severity={confirmationModal.type || "error"}
      />
      {/* First Name */}
      <Typography sx={classes.profileDetail}>First Name</Typography>
      <Typography gutterBottom>
        {firstName || t("dnpinput1")}
      </Typography>
      {/* Last Name */}
      <Typography sx={classes.profileDetail}>Last Name</Typography>
      <Typography gutterBottom>
        {lastName || t("dnpinput1")}
      </Typography>
      {/* Email */}
      <Typography sx={classes.profileDetail}>Email</Typography>
      <Typography gutterBottom>
        {email || t("dnpinput1")} 
      </Typography>
      {/* Phone Number */}
      <Typography sx={classes.profileDetail}>Phone Number</Typography>
      <Typography gutterBottom>
        {phoneNumber || t("dnpinput1")}
      </Typography>
      {/* Role */}
      <Typography sx={classes.profileDetail}>Role</Typography>
      <Typography gutterBottom>
        {usersConsoleRole[0]?.name || t("dnpinput1")}
      </Typography>
      <Grid sx={{ display: "flex", flexDirection: "column" }} size={6}>
        {/* Time Zone */}
        <AutocompleteSelect
          disabled={state.userTimeZoneUseDefault.value}
          label="Time Zone"
          noOptionsText="Start typing to search assemblies"
          getOptionLabel={(option) => option?.value}
          onChange={(_, value) =>
            setState((prevState) => ({
              ...prevState,
              timeZone: { ...prevState.timeZone, value },
            }))
          }
          options={timeZoneOptions}
          value={timeZone.value}
          variant="outlined"
        />

        {/* Use Browser Timezone */}
        <SimpleCheckbox
          checked={state.userTimeZoneUseDefault.value}
          label="Use Browser Timezone"
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              // We'll pop the timezone back to the default state.
              timeZone: {
                ...prevState.timeZone,
                value: { label: browserTimeZone, value: browserTimeZone },
              },
              userTimeZoneUseDefault: {
                ...prevState.userTimeZoneUseDefault,
                value: event.target.checked,
              },
            }));
          }}
        />

        <MaterialUiButton
          color="primary"
          fullWidth={false}
          label="Save Time Zone"
          onClick={handleSaveTimeZone}
          variant="contained"
        />
      </Grid>
    </Grid>)
  );
}

export default UserProfile;
