import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MapDisplayWidget from "./widgets/MapDisplayWidget/MapDisplayWidget";
import TrackerCount from "./widgets/TrackerCount";
import InventoryHistoryGrid from "./widgets/InventoryHistoryGrid";

export default function Dashboard(props) {
  const dashboardApps = [
    {
      name: "Map",
      description: "google map",
      size: 3,
    },
    {
      name: " Tracker Count",
      description: "tracker count",
      size: 1,
    },
    {
      name: "Inventory History Grid",
      description: "Inventory History Grid",
      size: 4,
    },
  ];

  const dashboardAppSwitch = (name) => {
    switch (name) {
      case "Map":
        return (
          <MapDisplayWidget {...props} />
        );
      case " Tracker Count":
        return <TrackerCount {...props} />;
      case "Inventory History Grid":
        return <InventoryHistoryGrid {...props} />;
      default:
        return <></>;
    }
  };

  return (
    (<Grid
      container
      sx={{
        justifyContent: "space-between",
        width: '84vw'
      }}
    >
      {dashboardApps.map((app, key) => (
        <Grid
          key={`${app.name}-${key}`}
          sx={{
            marginTop: "1rem",
            padding: ".5rem",
          }}
          size={{
            md: app.size * 3,
            xs: "12"
          }}>
          <Box
            sx={{
              height: "100%",
              minHeight: "50vh",
            }}
          >
            {dashboardAppSwitch(app.name)}
          </Box>
        </Grid>
      ))}
    </Grid>)
  );
}
