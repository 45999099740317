import { useCallback, useEffect, useState } from "react";
import { trimmedLink } from "../../../../utils/extractAssetId";
import { getAsset } from "../../../../utils/API/Assets/Assets";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import AsteriskSolid from "../../../../assets/faIcons/AsteriskSolid";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import InformationSlide from "./InformationSlide";
import MuiSVGIcon from "../../../IconPicker/MuiSVGIcon";

// This component was ported over from the LPC. Its main purpose is to grab input. Determine
// if its coming from a user OR a QR scan. It then grabs the assets info, and shoots it back out
// to the parent component.

export default function AssetIdTextField(props) {
  const {
    assetInfo,
    customIcon = <></>,
    error = false,
    externalLabel = true,
    formFields,
    label,
    labelPlacement = "top",
    labelStyles = {},
    multiline = false,
    onChange,
    readOnly = false,
    required = false,
    setAssetInfo,
    onDeleteIconClick = () => {},
    showCustomIcon = false,
    showDeleteIcon = false,
    showInfoIcon = true,
    size = "small",
    token,
    type = "text",
    value = "",
    variant = "outlined",
  } = props;
  const theme = useTheme();
  const { palette = {} } = theme;
  const { typography = {} } = palette;
  const [open, setOpen] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const { asset = {} } = assetInfo;
  const { assets = [], tag = null } = asset;

  const handleClickOpen = () => {
    setOpen(true);
  };

  function extrapolateAssetId(event) {
    const eventString = event.target.value;
    // We steralize the link
    const steralizedLink = trimmedLink(eventString);

    // We then turn it into an array (This is for when people paste the url in)
    const steralizedLinkSplit = steralizedLink.split("");

    // We then figure out if it is http or https
    const isHttp = steralizedLink.substring(0, 4) === "http";
    const isHttps = steralizedLink.substring(0, 5) === "https";

    // We then pull the url out of the array
    const extratedUrl = isHttps
      ? steralizedLinkSplit.slice(0, 61).join("")
      : steralizedLinkSplit.slice(0, 60).join("");
    let assetId = null;

    // Once the url is long enough to extract an assetID, we do so. And only do so once
    if (isHttps && extratedUrl.length === 61) {
      assetId = steralizedLink.substring(25, 61);
    } else if (isHttp && !isHttps && extratedUrl.length === 60) {
      assetId = steralizedLink.substring(24, 60);
    }

    // Is the assetId valid?
    const isValidAssetId = assetId !== null && assetId?.length === 36;

    if (isHttps && isValidAssetId && extratedUrl?.length === 61) {
      if (Object.keys(assetInfo).length === 0) {
        getAsset({ assetId: assetId, token }).then((res) => {
          setAssetInfo(res);
          onChange({ target: { assetInfo: res, value: assetId } });
        });
      }
      onChange(event);
    } else if (isHttp && isValidAssetId && extratedUrl?.length === 60) {
      getAsset({ assetId: assetId, token }).then((res) => {
        setAssetInfo(res);
        onChange({ target: { assetInfo: res, value: assetId } });
      });
    } else {
      onChange(event);
    }
  }

  useEffect(() => {
    // There will be no info to show if there are no children. Thus we
    // remove the icon if none are present
    if (!showInfoIcon || assets.length === 0) {
      setShowInformation(false);
    } else {
      setShowInformation(true);
    }
  }, [assets.length, showInfoIcon]);

  const debouncedChangeHandler = useCallback(
    _.debounce(extrapolateAssetId, 750),
    []
  );

  const formattedLabel = label?.replace(/\s+/g, "-");

  let labelWithAstrisk;
  if (required) {
    labelWithAstrisk = (
      <Box sx={{ display: "flex" }}>
        {label}
        <MuiSVGIcon
          icon={<AsteriskSolid />}
          sx={{
            color: "red",
            fontSize: "10px",
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
              display: "flex",
              alignItems: "center",
            }
          : {}
      }
    >
      {/* Information Slide */}
      {showInformation ? (
        <InformationSlide
          assetInfo={assetInfo}
          formFields={formFields}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      {/* Label */}
      {externalLabel ? (
        <Box
          sx={{
            color: typography?.secondary,
            display: "flex",
            fontFamily: "Lato",
            fontWeight: "bold !important",
            justifyContent: "space-between",
            lineHeight: 1,
            marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
            marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
            marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
            ...labelStyles,
          }}
        >
          <InputLabel
            id={formattedLabel}
            sx={{
              color: typography?.secondary,
              fontFamily: "Lato",
              fontWeight: "bold",
            }}
          >
            {required ? labelWithAstrisk : label}
            {showInformation ? (
              <InfoIcon
                color={tag?.length > 0 ? "inherit" : "disabled"}
                fontSize="small"
                sx={{
                  cursor: "pointer",
                  marginLeft: ".5rem",
                }}
                readOnly={true}
                onClick={() => {
                  if (tag.length > 0) {
                    handleClickOpen();
                  } else {
                    return;
                  }
                }}
              />
            ) : null}
          </InputLabel>
          {showDeleteIcon ? (
            <IconButton
              aria-label={`Delete ${label}`}
              onClick={() => onDeleteIconClick()}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          ) : null}
          {showCustomIcon ? customIcon : null}
        </Box>
      ) : null}
      <FormControl
        className="simple-select"
        error={error}
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        <TextField
          disabled={readOnly}
          id="assetId-field"
          label=""
          fullWidth
          multiline={multiline}
          onChange={(event) => {
            // If a device has already been detected, this will clear it out so the
            // user can enter in another tag
            if (tag !== null) {
              // We clear out the asset
              onDeleteIconClick();
            } else {
              onChange(event);
              debouncedChangeHandler(event);
            }
          }}
          type={type}
          size={size}
          slotProps={{
            htmlInput: { inputMode: "none", readOnly },
          }}
          value={tag ? tag : value}
        />
      </FormControl>
    </Box>
  );
}
