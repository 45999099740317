import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useHistory } from "react-router-dom";
import { useTranslation as UseTranslation } from "react-i18next";
import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function MapTable(props) {
  const history = useHistory();

  const { facilities, mapTableData, timeZone } = props;

  const { t } = UseTranslation();

  const handleClick = (row) => {
    const url = row.batchId
      ? `/batches/${row.batchId}/history/${row.assetType ? row.assetType : ""}`
      : `/assetSnapshot/${row.assetId}`;
    history.push(url);
  };

  const mapTableColumns = [
    {
      field: "timeOfLog",
      groupable: false,
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { row } = data;
        const { timeOfLogLong } = row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(row);
            }}
          >
            {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (row) => {
        const timeOfLogLong = row?.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
    },
    {
      field: "tag",
      headerName: "Asset Tag",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { tag } = row;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(row);
            }}
          >
            {tag ? tag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.tag ? row.tag : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.tag ? row.tag : null;
      },
    },
    {
      field: "lastEvent",
      headerName: "Last Event",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { lastEvent } = row;
        return (
          <div
            className={`pointer`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              handleClick(row);
            }}
          >
            {lastEvent ? lastEvent : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.lastEvent ? row.lastEvent : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.lastEvent ? row.lastEvent : null;
      },
    },
    {
      field: "facility",
      headerName: "Facility Name",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { facility, facilityId, latitude, longitude } = row;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(row);
            }}
          >
            {facility
              ? facility
              : !latitude || !longitude
                ? t("dnpinput1")
                : facilityId &&
                  facilities[facilityId] &&
                  facilities[facilityId].name
                  ? facilities[facilityId].name
                  : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { facilityId } = row;
        return facilityId &&
          facilities[facilityId] &&
          facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
      groupingValueGetter: (value, row) => {
        const { facilityId } = row;
        return facilityId &&
          facilities[facilityId] &&
          facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { propertiesMap } = row;
        const description = propertiesMap?.description;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(row);
            }}
          >
            {description ? description : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { propertiesMap } = row;
        return propertiesMap?.description
          ? propertiesMap.description
          : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { propertiesMap } = row;
        return propertiesMap?.description ? propertiesMap.description : null;
      },
    },
  ];

  return (
    <MaterialUiDataGridPremium
      height={"56vh"}
      checkboxSelection={false}
      columns={mapTableColumns}
      exportFileName={"Asset-Map-Table"}
      getRowId={(row) => row.assetId}
      rows={mapTableData ? mapTableData : []}
    />
  );
}
