import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "moment-timezone";
import { checkSelectedAssets } from "./utils";
import { getAssetsById } from "./api";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { isEqual, isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import { thunks } from "../../../globalStore/slices/assetHistory/assetHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import LaptopIcon from "@mui/icons-material/Laptop";
import Loading from "../../../components/Loading/Loading";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import MuiAlert from "@mui/material/Alert";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Snackbar from "@mui/material/Snackbar";
import Table from "./Table/Table";

// Components are super heavy, which is why we are using lazy/Suspense
const AssignDevice = lazy(() => import("../assignDevice/AssignDevice"));
const ImportAssets = lazy(() => import("../ImportAssets/ImportAssets"));

function AssetHistory(props) {
  const dispatchGlobal = useDispatch();
  const { searchHistories, saveFilterSettings } = thunks.assetHistory;

  const {
    apiUrl,
    appUserType,
    exportTable = false,
    location,
    organizationId,
    timeZone,
    token,
    userId,
    usersConsoleRole,
  } = props;

  // OK to destructure, as no changes to org data takes place in this page
  const {
    classifications,
    customs,
    devices,
    facilities,
    organization,
    products,
    usersMap,
    zones,
  } = useSelector((state) => state.organization, isEqual);

  let assetHistoryClassifications = {};

  Object.keys(classifications).forEach((key) => {
    let classification = classifications[key];
    if (classification.assetMode === "Asset" || !classification.assetMode) {
      assetHistoryClassifications[key] = classification;
    }
  });
  const assetCustoms = customs.filter((custom) => custom.assetMode === "Asset");

  const assetTypes =
    organization && organization.assetTypes
      ? [...organization.assetTypes].sort()
      : [];

  const eventTypes =
    organization && organization.eventTypesMap
      ? Object.keys(organization.eventTypesMap).sort()
      : [];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      })
    : [];

  const allDevices = Object.keys(devices).map((k) => devices[k]);
  const availableDevices = allDevices.filter(
    (item) => item.device.status !== "assigned"
  );

  const assetHistoryStore = useSelector((state) => state.assetHistory, isEqual);

  const { filters: initFilters = {}, showInstructions = false } =
    assetHistoryStore;

  // make sure dates are formatted correctly for our datepicker components
  const startDate = initFilters.startDate
    ? new Date(initFilters.startDate)
    : null;

  const endDate = initFilters.endDate ? new Date(initFilters.endDate) : null;

  const [state, setState] = useState({
    count: assetHistoryStore.count,
    filters: { ...initFilters, startDate, endDate, allDevicesMap: devices },
    lists: {
      assetTypes: assetTypes,
      eventTypes: eventTypes,
      eventTypesMap: organization.eventTypesMap || {},
      assetCategoriesList:
        organization.assetCategoriesList || organization.assetCategories || {},
      facilityArray: facilityArray,
    },
    allDevices,
    availableDevices,
    availableZones: Object.keys(zones).map((zone) => {
      // TODO: Is this how we are going to handle the differences between
      // pZones and tZones?
      return {
        internalZoneType: zones[zone].internalZoneType || null,
        label: zones[zone].name,
        value: zones[zone].zoneId,
      };
    }),
    selectedAsset: "",
    selectAll: false,
    selectedAssets: {},
    showInstructions: showInstructions,
    page: 0,
    mounted: true,
  });

  const [loading, setLoading] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState({
    modalShow: false,
    text: "",
    error: false,
  });

  const [modal, setModal] = useState({
    modal: { modalShow: false, title: "", content: {} },
  });
  const modalClose = () => setModal({ modal: { modalShow: false, ...modal } });

  const [dialog, setDialog] = useState({
    dialogShow: false,
  });

  const [isRedirect, setIsRedirect] = useState(false);

  const [importAssetsModalTitle, setImportAssetsModalTitle] =
    useState("Import Assets");

  const isMountedRef = useRef(false);

  // upon first mount, this function will check for updates in the store by dispatching the showLastEvents thunk
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(searchHistories(state)).then((res) => {
          isMountedRef.current = true;
          setState((prevState) => ({
            ...prevState,
            histories: res.payload.histories,
          }));
        });
      }
    },
    [dispatchGlobal, searchHistories, state]
  );

  // intializing effect, this is for checking for updated asset status on first mount using initial filters. Using a ref to ensure it only fires on first mount.
  useEffect(() => {
    init(isMountedRef.current);
  }, [init]);

  // this effect fires whenever filters are updated (either in the filter menu or by changing the page or row limit).
  // it checks the isMountedRef to prevent firing on first mount, sets loading to true, and then dispatches the showLastEvents thunk to our store to searchAssets and update state
  // it then takes the return from our showLastEvents thunk and updates the local state
  useEffect(() => {
    if (isMountedRef.current) {
      setLoading(true);
      dispatchGlobal(
        searchHistories({ filters: state.filters, page: state.page })
      ).then((results) => {
        setLoading(false);
        setState((prevState) => {
          return {
            ...prevState,
            histories: results.payload.histories,
            count: results.payload.count,
            page: Math.floor(state.filters.start / state.filters.limit),
          };
        });
      });
    }
  }, [dispatchGlobal, searchHistories, state.filters, state.page]);

  // import assets quickLink effect
  useEffect(() => {
    const { state = {} } = location;
    if (state.modal === "Import Assets") {
      setDialog({ dialogShow: true });
    }
  }, [location]);

  const handleSave = async (filters) => {
    return await dispatchGlobal(saveFilterSettings(filters));
  };

  async function checkDevices() {
    let isValid = false;
    let assets;
    let assetIds = [
      ...new Set(
        Object.keys(state.selectedAssets).map((asset) => {
          return asset;
        })
      ),
    ];

    // Retrieve up to date asset information
    assets = await getAssetsById(
      { token, apiUrl, organizationId },
      assetIds,
      1000
    );

    // Checks to verify selected asset(s) do not currently have a device associated
    const hasParentId = assets.assets.find((element) => element.parentId);

    // Checks to verify they actually have enough devices for the amount of selected assets
    const hasEnoughDevices =
      state.availableDevices.length < Object.keys(state.selectedAssets).length;

    if (hasEnoughDevices) {
      setConfirmationModalShow({
        modalShow: true,
        text: "You have selected more assets than there are available devices. Please adjust your selections.",
        error: true,
      });
    } else if (hasParentId) {
      setConfirmationModalShow({
        modalShow: true,
        text: "You have selected one or more assets that already has a device assigned to it.",
        error: true,
      });
    } else isValid = true;

    if (isValid) {
      setModal({
        modalShow: true,
        title: "Assign a Tracker",
        content: (
          <Suspense fallback={<Loading />}>
            <AssignDevice
              allDevices={state.allDevices}
              apiUrl={apiUrl}
              availableDevices={state.availableDevices}
              organizationId={organizationId}
              selectedAssets={state.selectedAssets}
              setConfirmationModalShow={(value) => {
                setConfirmationModalShow(value);
                setTimeout(() => {
                  setConfirmationModalShow({
                    modalShow: false,
                    text: "",
                    error: false,
                  });
                }, 1000);
              }}
              setModal={setModal}
              token={token}
              timeZone={timeZone}
            />
          </Suspense>
        ),
      });
    }
  }

  function toolBarButtons() {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        {/* Lite Users are unable to manipulate data */}
        {usersConsoleRole.some((role) => role.name === "Lite") || state.displayMap ? null : (
          <>
            {/* Assign a Tracker */}
            <HoverIconButton
              disabled={isEmpty(state.selectedAssets)}
              text="Assign a Tracker"
              icon={<LaptopIcon />}
              iconDirection="right"
              handleClick={() => {
                if (!isEmpty(state.selectedAssets)) {
                  checkDevices();
                }
              }}
            />

            {/* Generate CQRs Button */}
            <HoverIconButton
              text="Generate CQRs"
              icon={<QrCodeIcon />}
              iconDirection="right"
              disabled={isEmpty(state.selectedAssets)}
              handleClick={() => {
                if (!isEmpty(state.selectedAssets)) {
                  checkSelectedAssets(
                    { apiUrl, organizationId, token },
                    state
                  ).then((results) => {
                    const { faultyContent = [], isError = true } = results;
                    if (!isError) {
                      setIsRedirect(true);
                    } else {
                      setConfirmationModalShow({
                        modalShow: true,
                        text: `The Asset Tag(s)${faultyContent.map((item) => {
                          return ` ${item}`;
                        })} are shown as still being in circulation, therefore the CQR cannot be regenerated. If you need to reprint a CQR for an asset, please contact your organizations Admin User.`,
                        error: true,
                      });
                    }
                  });
                }
              }}
            />

            {/* Import Inventory Button */}
            <HoverIconButton
              text="Import"
              icon={<AssignmentReturnedIcon />}
              iconDirection="right"
              handleClick={() => {
                setDialog({ dialogShow: true });
              }}
              disabled={false}
            />
          </>
        )}
      </Grid>
    );
  }

  return exportTable ? (
    <Table
      allDevices={state.allDevices}
      apiUrl={apiUrl}
      appUserType={appUserType}
      classifications={assetHistoryClassifications}
      customs={assetCustoms}
      defaultColumnOrder={state.filters.defaultColumnOrder}
      dispatchGlobal={dispatchGlobal}
      facilities={facilities}
      handleSave={handleSave}
      loading={loading}
      organizationId={organizationId}
      page={state.page}
      products={products}
      searchHistories={searchHistories}
      setConfirm={setConfirmationModalShow}
      setLoading={setLoading}
      setModal={setModal}
      setState={setState}
      state={state}
      timeZone={timeZone}
      token={token}
      toolBarButtons={toolBarButtons}
      userId={userId}
      usersMap={usersMap}
      useSelector={useSelector}
      zones={zones}
    />
  ) : (
    <Grid
      className="asset-history"
      container
      style={{
        marginTop: "1rem",
        width: "84vw",
        margin: "0 auto",
      }}
    >
      {isRedirect ? (
        <Redirect
          to={{
            pathname: "/generateCQR/",
            state: { selectedAssets: state.selectedAssets },
          }}
        />
      ) : (
        ""
      )}
      <ModalDialog
        handleClose={modalClose}
        open={modal.modalShow ? modal.modalShow : false}
        title={modal.title}
        content={
          <Grid container>
            <Grid size={12}>
              {modal.content}
            </Grid>
          </Grid>
        }
      />
      <ModalDialog
        handleClose={() => {
          setDialog({
            dialogShow: false,
          });
        }}
        open={dialog.dialogShow ? dialog.dialogShow : false}
        title={importAssetsModalTitle}
        content={
          <Suspense fallback={<Loading />}>
            <ImportAssets
              apiUrl={apiUrl}
              availableDevices={state.availableDevices}
              classifications={assetHistoryClassifications || {}}
              facilities={facilities}
              onHide={() => {
                setDialog({
                  dialogShow: false,
                });
                setImportAssetsModalTitle("Import Assets");
              }}
              onSuccess={() => {
                // force refetch via useEffect
                setState({
                  ...state,
                  filters: {
                    ...state.filters,
                  },
                });
              }}
              organization={organization}
              setConfirm={setConfirmationModalShow}
              setImportAssetsModalTitle={setImportAssetsModalTitle}
              token={token}
            />
          </Suspense>
        }
      />

      {/* My assumption is that we are going to need to change the material confirmation modal to match the snack bar but for now ill set it here */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={
          confirmationModalShow.modalShow
            ? confirmationModalShow.modalShow
            : false
        }
        onClose={() => {
          setConfirmationModalShow((prevState) => ({
            ...prevState,
            modalShow: false,
          }));
        }}
      >
        <MuiAlert
          onClose={() => {
            setConfirmationModalShow((prevState) => ({
              ...prevState,
              modalShow: false,
            }));
          }}
          severity={confirmationModalShow.error ? "error" : "success"}
        >
          {confirmationModalShow.text}
        </MuiAlert>
      </Snackbar>

      <Grid size={12}>
        <Box marginTop="1rem">
          {state.filters ? (
            <Suspense fallback={<Loading />}>
              <Table
                allDevices={state.allDevices}
                apiUrl={apiUrl}
                appUserType={appUserType}
                classifications={assetHistoryClassifications}
                customs={assetCustoms}
                defaultColumnOrder={state.filters.defaultColumnOrder}
                dispatchGlobal={dispatchGlobal}
                facilities={facilities}
                handleSave={handleSave}
                loading={loading}
                organizationId={organizationId}
                page={state.page}
                products={products}
                searchHistories={searchHistories}
                setConfirm={setConfirmationModalShow}
                setLoading={setLoading}
                setModal={setModal}
                setState={setState}
                state={state}
                timeZone={timeZone}
                token={token}
                toolBarButtons={toolBarButtons}
                userId={userId}
                usersMap={usersMap}
                useSelector={useSelector}
                zones={zones}
              />
            </Suspense>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AssetHistory;
